import Keycloak from "keycloak-js";
import { KEYCLOAK, SSO_CLIENT_ID, SSO_REALM } from "./Constants/api.constant";
import { jwtDecode } from "jwt-decode";

let lastActivityTime = Math.floor(Date.now() / 1000);

const updateLastActivityTime = () => {
  lastActivityTime = Math.floor(Date.now() / 1000);
};

const addActivityListeners = () => {
  document.addEventListener("mousemove", updateLastActivityTime); // Mouse movement
  document.addEventListener("mousedown", updateLastActivityTime); // Mouse button click
  document.addEventListener("keydown", updateLastActivityTime); // Keyboard key press
  document.addEventListener("scroll", updateLastActivityTime); // Page scroll
};

addActivityListeners();

const keycloakConfig = {
  url: window?.API_BASE_URL + KEYCLOAK,
  realm: SSO_REALM,
  clientId: SSO_CLIENT_ID,
  redirectUri: window?.API_BASE_URL,
};

const keycloak = new Keycloak(keycloakConfig);

const setKeycloakStorage = () => {
  const decoded = jwtDecode(keycloak?.token);
  if (decoded.email) {
    sessionStorage.setItem("refresh_token", `${keycloak?.refreshToken}`);
    localStorage.setItem("access_token", `${keycloak?.token}`);
    sessionStorage.setItem("authServerId", `${decoded.upn}`);
    localStorage.setItem("userId", `${decoded.upn}`);
    localStorage.setItem("emailId", `${decoded.email}`);
    localStorage.setItem("userName", `${decoded.preferred_username}`);
  }
};

const refreshToken = async () => {
  try {
    const token = keycloak.tokenParsed;
    const currentTime = Math.floor(Date.now() / 1000);
    const tokenExpiration = token?.exp;
    const timeToRefresh = 60;
    const idleTimeThreshold = 20 * 60;

    if (currentTime - lastActivityTime > idleTimeThreshold) {
      logout();
      return;
    } else if (tokenExpiration - currentTime <= timeToRefresh) {
      const refreshed = await keycloak.updateToken(70);
      setKeycloakStorage();
    }
    setTimeout(refreshToken, 60000);
  } catch (error) {
    setTimeout(refreshToken, 60000);
  }
};

refreshToken();

const initKeycloak = (onAuthenticatedCallback) => {
  keycloak
    .init({ onLoad: "login-required" })
    .then((authenticated) => {
      if (authenticated) {
        setKeycloakStorage();
        onAuthenticatedCallback();
      } else {
        window.location.reload();
      }
    })
    .catch((error) => console.log("error"));
};

const logoutOptions = { redirectUri: window?.API_BASE_URL };

const logout = () => {
  keycloak.logout(logoutOptions);
};

export { keycloak, initKeycloak, logout };
