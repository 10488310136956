import React from "react";
import ReactDOM from "react-dom/client";
import "./Fonts/Poppins/Poppins_Fonts.css";
import "./Fonts/Roboto/Roboto_Fonts.css";
import "../src/style.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { initKeycloak } from "./keycloak";

reportWebVitals();

window?.IS_SSO_ENABLED === "TRUE"
  ? initKeycloak(() => {
      renderApp();
    })
  : renderApp();

function renderApp() {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}
