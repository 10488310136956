import React, { useState } from "react";
import "./loginpage.scss";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import {baseURL } from "../../Configuration/ServerConfig";
import { toastNotify } from "../../Container/toastServices";
// import headerLogo from "../../assets/branding-image/canvas-ai-logo.svg";
import LNTCS from "./images/LntCS.png";

const LoginPage = () => {
  const [emailId, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // const [passwordShown, setPasswordShown] = useState(false);

  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "emailId") {
      setEmail(value.trim());
    } else if (name === "password") {
      setPassword(value);
    }
  };
  const validateInput = () => {
    let validationMessage = "";
    if (password !== "" && password.length < 6) {
      validationMessage = "Minimum 6 characters are required";
    }
    return validationMessage;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const errorRes = validateInput();
    if (errorRes !== "") {
      toastNotify(errorRes, "error");
    } else {
      const obj = {
        grant_type: "password",
        client_id: "canvasai-chat",
        username: emailId,
        password: password,
      };
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
      };
      axios
        .post(
          `${baseURL}/keycloak/realms/canvasai/protocol/openid-connect/token`,
          obj,
          {
            headers: headers,
          }
        )
        .then((res) => {
          const decoded = jwtDecode(res.data?.access_token);
          sessionStorage.setItem("refresh_token", `${res.data?.refresh_token}`);
          sessionStorage.setItem("authServerId", `${decoded.sub}`);
          localStorage.setItem("userId", `${decoded.sub}`);
          localStorage.setItem("emailId", `${emailId}`);
          localStorage.setItem("userName", `${decoded.preferred_username}`);
          localStorage.setItem("access_token", `${res?.data?.access_token}`);
          //getUserAuthServerId(decoded.sub)
          toastNotify("Login successful", "success");
          navigate(`/app`);
        })
        .catch((error) => {
          console.log(error);
          if (error?.response?.status === 401) {
            toastNotify(error?.response?.data?.error_description, "error"); 
          } else {
            toastNotify("Something went wrong", "error");
          }
        });
    }
  };

  return (
    <div className="authenticateContainer row mx-0 py-3 justify-content-center">
      <div className="col-lg-5">
        <div className="botIcon ">
          {/* <img src={"lnt/assets/images/loginpagebot.svg"} alt=""></img> */}
          <img src={LNTCS} alt="LnTCS Logo" />
          <h1 className="loginTitle">Unlock the power of AI</h1>
          <h5 className="loginSubTitle">Powered by canvas.ai</h5>
        </div>
      </div>
      <div className="col-lg-5">
        <div className="loginForm">
          <div className="loginContent">
            <div className="formTitle">
              {/* <img
                className="infinityLogo"
                src={headerLogo}
                alt="Canvas-Gear"
              ></img> */}
              <h4>Enter your details to sign in</h4>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="inputEmail4">Email</label>
                  <div className="formWrapper">
                    <div className="input-icon email">
                      <img
                        src={"lnt/assets/icons/emailIcon.svg"}
                        alt="emailIcon"
                      ></img>
                    </div>
                    <input
                      type="email"
                      className="form-control loginPage"
                      //id="inputEmail4"
                      placeholder="Enter your email"
                      name="emailId"
                      value={emailId}
                      onChange={handleChange}
                      autoComplete="off"
                      required
                      //onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="inputEmail4">Password</label>
                  <div className="formWrapper">
                    <div className="input-icon">
                      <img
                        src={"lnt/assets/icons/lockIcon.svg"}
                        alt="lock icon"
                      ></img>
                    </div>
                    <input
                      type="password"
                      className="form-control loginPage"
                      //id="inputEmail4"
                      placeholder="Enter your password"
                      name="password"
                      value={password}
                      onChange={handleChange}
                      autoComplete="off"
                      required
                      //onBlur={handleBlur}
                    />
                  </div>
                </div>
              </div>
              <div className="loginSubFooter">
                <div className="remember-check-wrap">
                  <input
                    type="checkbox"
                    className="check"
                    //id="inputEmail4"
                    name="emailId"
                    onChange={handleChange}
                    autoComplete="off"
                    //onBlur={handleBlur}
                  />
                  <label className="checkboxTitle" htmlFor="inputEmail4">
                    Remember me
                  </label>
                </div>
                <label className="form-label" htmlFor="form2">
                  Forgot password?
                </label>
              </div>
              <div className="btn-allign">
                <button
                  type="submit"
                  className="btn btn-primary custom-btn w-100 py-2"
                >
                  Sign in
                </button>
                <p className="mb-0">
                  Dont have an account ? <span>Sign up</span>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
